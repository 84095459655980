<script>
import noFind from '@/assets/images/404.png'
export default {
  name: 'NoFind',
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  },
  render() {
    return (
      <div class="contaniner">
        <div class="no-find">
          <img src={noFind} width="600" height="500" />
          <div class="sorry">
            <p class="p1">Sorry！您的页面走丢了！</p>
            <p class="p2">别紧张，刷新页面试试</p>
            <div class="btn" on-click={() => this.goBack()}>
              返回上一页
            </div>
          </div>
        </div>
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.contaniner {
  width: 100%;
  height: 100%;
  background: #f8fbff;
  border-radius: 0px 0px 0px 0px;
  position: relative;
  .no-find {
    height: 500px;
    width: auto;
    top: 50%;
    margin-top: -250px;
    left: 280px;
    position: absolute;
    display: flex;
    .sorry {
      margin-left: 80px;
      width: auto;
      height: 500px;
      .p1 {
        height: 55px;
        font-size: 42px;
        font-weight: bold;
        color: #7e8fb7;
        line-height: 55px;
        margin-top: 155px;
      }
      .p2 {
        height: 31px;
        font-size: 24px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #7e8fb7;
        line-height: 31px;
        margin-top: 22px;
        text-align: left;
      }
      .btn {
        width: 120px;
        height: 48px;
        border-radius: 24px 24px 24px 24px;
        opacity: 1;
        border: 1px solid #2395f3;
        margin-top: 32px;
        font-size: 16px;
        font-weight: 400;
        color: #2395f3;
        line-height: 48px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>
